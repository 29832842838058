import {Button, Container, createStyles, Group, Stack, Text, Title} from '@mantine/core';
import {Link, useNavigation} from '@remix-run/react';
import type {ReactNode} from 'react';

const useStyles = createStyles(theme => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
  },
}));

interface ErrorPageProps {
  title: string;
  description?: string;
  errorCode?: ReactNode;
  redirectTo?: string;
  redirectLabel?: string;
}

export function ErrorPage({
  title,
  description,
  redirectTo = '/',
  redirectLabel = 'Take me back',
  errorCode = '404',
}: ErrorPageProps) {
  const {classes} = useStyles();
  const {state} = useNavigation();

  return (
    <Container className={classes.root}>
      <Stack spacing={50}>
        <div className={classes.label}>{errorCode}</div>
        <Title className={classes.title}>{title}</Title>
        {description && (
          <Text color="dimmed" size="lg" align="center" className={classes.description}>
            {description}
          </Text>
        )}
        {redirectTo && (
          <Group position="center">
            <Button component={Link} to={redirectTo} variant="subtle" size="md" loading={state !== 'idle'}>
              {redirectLabel}
            </Button>
          </Group>
        )}
      </Stack>
    </Container>
  );
}
